
// @font-face {
//   font-family: 'Open Sans', sans-serif;
//   font-style: normal;
//   font-weight: 700;
//   src: url("./assets/font/OpenSans-Bold.ttf") format('.ttf');
// }

// @font-face {font-family: 'Open Sans', sans-serif;
//   font-style: normal;
//   font-weight: 400;
//   src: url('./assets/font/OpenSans-Regular.ttf') format('.ttf');
// }

// @font-face {
//   font-family: 'Open Sans', sans-serif;
//   font-style: normal;
//   font-weight: 600;
//   src: url('./assets/font/OpenSans-SemiBold.ttf') format('.ttf');
// }

// @font-face {
//   font-family: 'Open Sans', sans-serif;
//   font-style: normal;
//   font-weight: 300;
//   src: url('./assets/font/OpenSans-Light.ttf') format('.ttf');
// }
// @font-face {
//   font-family: 'Norsal';
//   src: url('./assets/font/norsal/Norsal.woff2') format('woff2'),
//       url('./assets/font/norsal/Norsal.woff') format('woff'),
//       url('./assets/font/norsal/Norsal.ttf') format('truetype');
//   font-weight: 600;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: 'Norsal';
  src: url('./assets/font/norsal/Norsal-Regular.woff2') format('woff2'),
      url('./assets/font/norsal/Norsal-Regular.woff') format('woff'),
      url('./assets/font/norsal/Norsal-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* You can add global styles to this file, and also import other style files */
@import '@angular/cdk/overlay-prebuilt.css'; /** Add this only for non-material project */
// @import '~ngx-sharebuttons/themes/default/default-theme';//manualy commented
@import './assets/custom-css/arb-custom.scss';

@import './assets/leaflet/leaflet.css';
@import './assets/leaflet/MarkerCluster.css';
@import './assets/leaflet/MarkerCluster.Default.css';
@import './assets/leaflet/leaflet.markers.css';
@import './assets/leaflet/full-screen/leaflet.fullscreen.css';
// @import './assets/ec-assets/css/bootstrap.min.css';
@import './assets/ec-assets/css/animate.css';
// @import './assets/ec-assets/css/font-awesome.min.css';
@import './assets/ec-assets/css/jquery.modal.min.css';
@import './assets/css/phone_number.css';
@import './assets/ec-assets/scss/arb.css';
@import './assets/ec-assets/scss/custom.css';
@import '../node_modules/mapbox-gl/dist/mapbox-gl.css';
@import "leaflet-routing-machine/dist/leaflet-routing-machine.css";



html {
  scroll-behavior: smooth;
}


html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Manrope', sans-serif !important;
}
html[dir=rtl],
html[dir=rtl] body,
html[dir=rtl] body h1,
html[dir=rtl] body h2,
html[dir=rtl] body h3,
html[dir=rtl] body h4,
html[dir=rtl] body h5,
html[dir=rtl] body li,
html[dir=rtl] body a,
html[dir=rtl] body span{
  font-family: 'Norsal', sans-serif !important;
}
button{
  border: 0 solid transparent;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  margin: 0;
}
.pointer {
  cursor: pointer;
}
.bg-sp-hover{
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 5px;
  img{
    margin-top: -8px;
  }
  &:hover{
    background-color: #0000004f;
    box-shadow: 0px 2px 3px 0px #999;
  }
}
.bg-hover{
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 5px;
  img{
    margin-top: -8px;
  }
  &:hover{
    background-color: #f6f6f6;
    box-shadow: 0px 2px 3px 0px #999;
  }
}
.disabled{
  pointer-events: none!important;
  color: #afafaf!important;
}
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  @media (max-width: 768px) {
    width: 0px;
    height: 0px;
  }
}
::-webkit-scrollbar-thumb {
  background: #959595;
  border-radius: 1px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e5e5e5;
  border-radius: 1px;
}
.row {
  width: 100%;
  // padding: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.mat-body,
.mat-body-1,
.mat-typography {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}



.mat-spiner {
  position: fixed;
  z-index: 9999;
  top: 45%;
  left: 48.57%;
  width: 50px !important;
  height: 50px !important;
  mat-spinner {
    width: 50px !important;
    height: 50px !important;
    svg {
      width: 50px !important;
      height: 50px !important;
    }
  }
}

  .map-default-img{
    width: 50% !important;
    margin: 25px auto;
  }
  .map-default-icon{
    width: 40px !important;
    margin: 15px auto;
  }
  .leaflet-popup {
    margin-bottom: 16px;
  }
  .map-property-card {
    background: #fff;
    border-radius: 10px;
    // min-width: 280px;
    font-family: 'Manrope', sans-serif !important;
    &::after{
      content: " ";
      position: absolute;
      bottom: -5px;
      border-bottom: none;
      width: 17px;
      height: 17px;
      z-index: 0;
      transform: rotate(45deg);
      background: #fff;
      box-shadow: 1px 1px 0px -1px #bebebe;
      left: 46%;
      opacity: 0;
    }
  }

  .leaflet-popup-content-wrapper{
    bottom: 5px;
    position: relative;
  }

  .map-card-header{
    position: relative;
    width: 100%;
    max-height: 80px;
    text-align: center;
    // border-bottom: 1px solid #eee;
    color: #555;
    .item2 {
      font-size: 11px;
      font-weight: 600;
      text-align: right;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80px;
    }
    .item1 {
      // font-weight: 600;
      font-size: 12px;
    }
  }
 .icon-img-div{
  background-color: #d9d9d9;
   border-top-left-radius: 10px;
   border-top-right-radius: 10px;
  //  height: 6.2rem;
   .item2 {
    font-size: 11px;
    color: #fff;
    text-align: right;
  }
// chnage for  near by property on popup//
  .item1 {
    font: normal normal 600 16px/18px "Manrope";
     text-align: left;
    color: #fff;
     overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:3;
  }

 }

  .img-card{
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .map-proItem {
    padding: 3px 10px;
    color: #555;
    .item2 {
      font-size: 11px;
    }
    .item1 {
      font-weight: 600;
      font-size: 11px;
      color: #000;
    }
  }
  .map-property_address{
    // height: 45px;
    p{

      font: normal normal 600 13px/18px "Manrope";
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: #000;
      // margin: 15px  0 !important;
      padding: 0 10px;

    }
  }
  .map-layer {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0;
    background: -webkit- linear-gradient(to top, black, transparent) left
      repeat;
    background: linear-gradient(to top, black, transparent) left repeat;
  }
  .map-divider{
    border-bottom: 1px solid #eee;
  }
  .leaflet-popup-content {
    max-width: 210px!important;
    min-width: 210px!important;
    font-family: 'Manrope', sans-serif !important;
}
.mat-menu-panel{
  max-width: 0px!important;
  min-width: 0px!important;
}
.mat-mdc-checkbox-frame, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  border-color: #f69230!important;
}
.mat-mdc-checkbox-indeterminate.mat-accent .mdc-checkbox__background, .mat-mdc-checkbox-checked.mat-accent .mdc-checkbox__background {
  background-color: #f69230!important;
  border-color: #f69230!important;
}
.lang_web{
  display: block;
  @media (max-width:768px) {
    display: none;
  }
}
.language{
  display: none;
  color: #000;
  background-color: #F5F6F7;
  padding: 15px 50px;
  font-size: 15px;
  border-bottom: 1px solid #e3e3e3;
  &.active{
    position: relative;
    span{
      margin-right: 20px;
    }
    &::after{
      content:'';
      position:absolute;
      background-image: url(./assets/icons/check_icon.svg);
      height: 15px;
      width: 30px;
      background-repeat: no-repeat;
      top: 19px;
    }
  }
  @media (max-width:768px) {
    display: block;
  }
}


.education {
  background-color: #a71f57 !important;
}
.bank {
  background-color: #236ad4 !important;
}
.religious {
  background-color: #0a9500 !important;
}
.healthcare {
  background-color: #236ad4 !important;
}
.parking {
  background-color: #009581 !important;
}
.shopping {
  background-color: #9c14cc !important;
}
.retail {
  background-color: #9c14cc !important;
}
.park {
  background-color: #dc7d00 !important;
}
.cafe {
  background-color: #b9a100 !important;
}
.icons {
  height: 40px;
  width: 40px;
  border-radius: 25px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #555;
}
.slider-wrapper{
  display: block;
  min-height: 200px;
  padding: 0 5px 5px;
  background:#664FD9;
  background: #ededed;
}
.slider-wrapper .owl-nav{
    margin-top: 10px;
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    transform: translateY(-50%);
}
.slider-wrapper .owl-theme .owl-nav [class*="owl-"] {
  background: transparent !important;
  line-height: 1.5;
  opacity: 0.5;
}
.slider-wrapper .owl-theme .owl-nav [class*="owl-"]:hover {
  opacity: 0.8;
}
.customPadding .mat-dialog-container{padding:0}
.rotate-180{transform: rotate(-180deg);}
.leaflet-control-layers-toggle {
  width: 30px !important;
  height: 30px !important;
  @media (min-width : 1280px) {
    width: 35px !important;
    height: 35px !important;
  }
}
.mobile_map .leaflet-top .leaflet-control {
  margin-top: 29px;
}
.mat_chiplist .mat-chip-list-wrapper .mat-standard-chip {
  font-size: 13px;
}
.mat_chiplist .mat-chip.mat-standard-chip {
  background-color: #eee;
}
.mat-error {font-size: 12px;}
app-mobile-header-dash.active_filter span{
  border: 1px solid #ee7435 !important;
  background-color: rgb(248 160 92 / 31%);
}
.table td, .table th {
  vertical-align: middle !important;
  white-space: nowrap;
}
.dialogCustom .mat-dialog-container{
  padding: 0;
}

::ng-deep .lg-outer * {
  box-sizing: border-box !important;
  direction: ltr !important;
}
.lg-outer * {
  box-sizing: border-box !important;
  direction: ltr !important;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  // z-index: 9999 !important;
}
// Tracking
.track-option{
  width: 280px;
  position: absolute;
  background: #fff;
  top: calc(100% - 13px);
  right:10px;
  z-index: 11;
  box-shadow: 0px 2px 5px 0px #ccc;
  border-radius: 2px;
  @media (max-width : 767px){
      right: auto;
      left: 50%;
      transform: translateX(-50%);
  }
  .mat-mdc-checkbox-disabled {
      opacity: 0.7;
  }
  .track-option-header{
      background: #ee7435;
      height: 35px;
      text-align: center;
      color: #fff;
      padding: 7px 10px;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
  }
  .track-option-body > div{
      border-bottom: 1px solid #ddd;
  }
  .track-option-footer{
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      button{
          margin: 0 6px;
          &.cancel{
              border: 1px solid #ddd;
              color: #000;
              background: #fff;
              padding: 5px 10px;
              border-radius: 4px;
              font-size: 13px;
          }
          &.startTrack{
              border: 1px solid
#664FD9;
              color: #fff;
              background:
#664FD9;
              padding: 5px 10px;
              border-radius: 4px;
              font-size: 13px;
              &.disabled{
                  background-color: #c4c4c4;
                  color: #6a6a6a !important;
                  cursor: no-drop;
                  border: 1px solid #c4c4c4;
              }
          }
      }

  }
}
.trackBar{
  background: #ee7435;
  padding: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 2px solid #ee7435;
  @media (max-width : 767px){
      flex-direction: column;
      text-align: center;
      row-gap: 8px;
  }
  &.tracking{
      background-color: #fff;
      p{
          color: #333333;
      }
  }
  p{
      color: #fff;
      font-size: 13px;
      margin: 0;
      span{
          &:first-child, &:last-child{
              font-weight: 600;
          }
      }
  }
  button{
      background: #fff;
      color: #ee7435;
      font-size: 13px;
      padding: 4px 25px;
      border-color: #ee7435;
      &.tracked-btn, &.tracked-btn:hover{
          background: #7cc24c;
          border-color: #7cc24c;
          color: #fff;
          cursor: default !important;
      }
      &:hover{
        background-color: #fff;
      }
  }
}

.mat-tooltip.item_tooltip{
  margin: 3px !important;
}
.create-master-panel .mat-dialog-container{
  padding: 0 !important;
  .mat-dialog-content{
    margin: 0;
    padding: 0;
  }
  .mat-dialog-actions{
    margin-bottom: 0;
  }
}
.disableLang{
  pointer-events: none;
  opacity: 0.35;
}
.investment_progressbar {
  margin-top: 30px;
  &.mat-progress-bar {
    height: 15px !important;;
    border-radius: 12px;
    border: 1px solid #A65DE6;
  }
  .mat-progress-bar-fill::after {
    background-color:#664FD9;
  }
  .mat-progress-bar-buffer {
    background-color: #fff;
  }
}
.breadcrumb_details{
  margin-top: 40px;
  ul{
      display: flex;
      column-gap: 20px;
      li{
          color: #707070;
          a{
              position: relative;
              text-decoration: underline;
              padding: 3px;
              &::after{
                  content: '>';
                  position: absolute;
                  left: calc(100% + 5px);
                  top: 4px;
              }
          }
      }

  }
}
// mat=====
.mdc-icon-button{
  font-size: 18px !important;
  width: 48px;
  height: 48px;
  padding: 0px !important;
}
.mdc-menu-surface.mat-mdc-select-panel{
  min-width: 120px;
  max-width: 100%;
  padding: 0 0 !important;
  max-height: 240px !important;
}
.mat-mdc-form-field-infix{
  min-height: 40px !important;
}
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix{
  padding: 7px 0!important;
}
.mdc-text-field--filled {
  background-color: transparent !important;
}
.mdc-text-field--filled:hover {
  background-color: transparent !important;
}
.mdc-menu-surface.mat-mdc-autocomplete-panel{
  padding: 0px !important;
}
.mat-mdc-form-field-error {
  font-size: .8rem;
}
.cdk-overlay-pane:not(.mat-mdc-select-panel-above) .mdc-menu-surface.mat-mdc-select-panel.myPanelClass1{
  transform: translate(-13px, 2px) !important;
}
html[lang=en] [dir=rtl] .mat-mdc-option .mdc-list-item__primary-text {
  margin-right: auto;
  margin-left: 0;
}
.disableList{
  // pointer-events: none;
  opacity: 0.6;
}
.disableListsearch{
  opacity: 0.6;
}
.warnDisabled{position: absolute;bottom: -5px; color: orange !important;display: block;}
@media(max-width: 767px){.warnDisabled{bottom: 0px; }}

.attibutes-title .mdc-form-field>label{
  margin: 0 !important;
  font-size: 13px;
}
.attibutes-title .mat-mdc-radio-button .mdc-radio {
  padding: calc((var(--mdc-radio-state-layer-size, 33px) - 23px) / 2) !important;
  cursor: pointer !important;
}
.filter-selection .mdc-form-field--align-end>label{
  margin: 0 !important;
}
.attr-placeholder .mdc-form-field--align-end>label{
  font-size: 12px !important;
}
.items_container{
  margin-top: 15px;
  z-index: 0;
  overflow: hidden;
  position: relative;
}
.lg-backdrop {
  background-color: rgb(26 32 49 / 90%) !important;
}
app-alert > div {
  z-index: 99991 !important;
}

.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

.leaflet-tooltip-own {
  position: absolute;
  padding: 4px;
  // background-color: rgba(0, 0, 0, 0.4);
  background-color: #00b0ff;
  border: 1px solid #fff;
  color: #fff;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0,0,0,0.4);
}
.link-color{
  font-weight: 600;
  color: #000;
}
ul {
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 0;
  // margin-bottom: 0px !important;
}
.un-order-list{
  li{
    font-size: 15px;
    line-height: 25px;
    color: #333333;
    margin-top: 15px;
    padding-left: 20px;
    position: relative;
    &::before{
      content: "";
      position: absolute;
      height: 7px;
      width: 7px;
      border-radius: 50%;
      top: 10px;
      left: 0;
      background-color: #ee7435;
    }
  }
}
.banner_section_inner sup {
  background: #E06C23 0% 0% no-repeat padding-box;
  border-radius: 28px;
  font-size: 14px;
  color: #fff;
  padding: 3px 20px 4px;
  line-height: 0;
  font-weight: normal;
  top: -1.3rem;
  left: -3px;
  @media (max-width: 992px) {
    padding: 1px 12px 2px;
    top: -1.1rem;
  }
}
.btn_blue, .btn_blue:hover, .btn_blue:active, .btn_blue:focus{
  background: #6449DB 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  color: #fff !important;
}
.btn_blue:hover{
  opacity: 0.9;
}
.estater_logo {
  display: inline-block;
  position: relative;
  background-image: url('assets/ec-assets/images/text_logo.svg');
  background-position: center center;
  background-size: 97%;
  background-repeat: no-repeat;
  -webkit-text-fill-color: transparent;
}
.home_page .slick-dots li button:before {
  font-size: 12px;
}
ul.list__item {
  font-size: 1.1rem;
  margin-top: 25px;
  margin: 25px 0 10px;
  line-height: 1.5;
  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
  label {
    font-weight:600;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  li {
    padding: 5px 10px;
    margin: 0 20px;
    list-style-type: auto;
  }
}
.follow_socialmedia li a {
  svg{
    fill: #644fe8;
    width: 16px;
  }
  &:hover svg{
    fill: #ffffff;
  }
}
.footer_social  li a {
  svg{
    fill: #000000;
    width: 13px;
  }
  &:hover svg{
    fill: #644fe8;
  }
}
.social_media_icon .footer_social  li a {
  svg{
    width: 15px;
  }
}
// .smartbanner {
//   &--android, &--ios {
//     background: #664FD9 !important;
//     background-image: none !important;

//     .smartbanner__info--title {
//       color: white !important;
//       font-weight: 600 !important;
//       letter-spacing: 2px !important;
//       font-size: 1.4rem !important;
//     }
//   }

//   &--android { color: white !important; }
//   &--ios { color: black !important; }

//   &__button {
//     display: block !important;
//     padding: 0.625rem 1.2rem !important;
//     background: #f3f3f3 !important;
//     color: #090909 !important;
//     font: {
//       size: 1rem !important;
//       weight: 600 !important;
//     }
//     text-align: center !important;
//     text-decoration: none !important;
//     border-radius: 8px;
//   }

//   &__info {
//     display: block !important;
//     color: #ccc !important;
//     // justify-content: space-evenly !important;

//     &--price {

//       padding-top: 0.5rem;
//       font: {
//         size: 0.7rem !important;
//         weight: 600 !important;
//       }
//     }
//   }

//   &--ios .smartbanner__exit {
//     border-radius: 50% !important;
//     height: 18px !important;
//     width: 18px !important;
//     background-color: black !important;

//     &::before,
//     &::after {
//       top: 3px !important;
//       left: 8px !important;
//       width: 2px !important;
//       height: 11px !important;
//       background: #b1b1b3 !important;
//     }
//   }
// }
